import { isDateValid } from '@naturehouse/nh-essentials/lib/dates/date';
import { matchMediaAddEventListener } from '@naturehouse/nh-essentials/lib/polyfills/matchMedia';
import { formatDate, getMonthsDiff } from '../../../util/dateHelper';
import DateRangeSelector from '../DateRangeSelector';
import CalendarBase from './CalendarBase';

export enum DatePickerCalendarEvents {
    DATE_RANGE_SELECTED = 'date-range-selected',
    SHOW_MORE_MONTHS = 'show-more-months'
}

export enum DatePickerCalendarSelectState {
    START = 'select-start',
    END = 'select-end'
}

export default class DatePickerCalendar extends CalendarBase {
    readonly #showMoreMonthsStep = 6;

    #calendarBase: HTMLElement | null = null;

    #dateSelection: DateRange = { start: null, end: null, id: 0 };

    #rangeSelector: DateRangeSelector;

    #current = 0;

    #calendarCounter = 4;

    #allowedDates: string[] | null = null;

    #selectState: DatePickerCalendarSelectState | null = null;

    readonly #tabletMediaQuery: MediaQueryList;

    #showHideObserver: MutationObserver | undefined;

    #firstArrivalDate: Date | null = null;

    static get observedAttributes(): string[] {
        return ['selectedperiod'];
    }

    set firstArrivalDate(firstArrivalDate: Date | null) {
        this.#firstArrivalDate = firstArrivalDate;
    }

    get firstArrivalDate(): Date | null {
        return this.#firstArrivalDate;
    }

    get selection(): DateRangeString {
        const start = this.#dateSelection.start;
        const end = this.#dateSelection.end;

        return {
            start: start ? formatDate(start) : null,
            end: end ? formatDate(end) : null
        };
    }

    get selectedDateRange(): DateRange {
        return {
            start: this.#dateSelection.start,
            end: this.#dateSelection.end
        };
    }

    set selectedDateRange(range: DateRange) {
        this.#rangeSelector.startDate = range.start;
        this.#rangeSelector.endDate = range.end;
        this.#dateSelection.start = range.start;
        this.#dateSelection.end = range.end;
        this.#update();
    }

    get selectState(): DatePickerCalendarSelectState | null {
        return this.#selectState;
    }

    set selectState(value: DatePickerCalendarSelectState | null) {
        this.#selectState = value;
        this.#update();
    }

    set allowedDates(dates: string[]) {
        const firstUpdate = this.#allowedDates === null;

        this.#allowedDates = dates;
        this.#update();

        if (firstUpdate && this.#dateSelection.start !== null) {
            this.dispatchEvent(
                new CustomEvent(DatePickerCalendarEvents.DATE_RANGE_SELECTED, {
                    detail: {
                        start: this.#dateSelection.start,
                        end: this.#dateSelection.end,
                        firstUpdate
                    }
                })
            );
        }

        if (this.firstArrivalDate) {
            const now = this.start;
            const firstDate = new Date(this.selection.start || this.firstArrivalDate);
            const monthsDiff = getMonthsDiff(now, firstDate);
            if (monthsDiff === 0) {
                return;
            }

            this.#navigateDatepicker(monthsDiff - this.#current);
        }
    }

    public constructor() {
        super();

        const tablet = getComputedStyle(document.documentElement).getPropertyValue('--tablet');
        this.#tabletMediaQuery = window.matchMedia(tablet);
        const isMobile = !this.#tabletMediaQuery.matches;

        const now: Date = new Date();
        const start: Date =
            !isMobile && this.start && this.start.getTime() >= now.getTime()
                ? this.start
                : new Date(now.getFullYear(), now.getMonth());

        const year = start.getFullYear();
        const month = start.getMonth();

        this.start = new Date(now.getFullYear(), now.getMonth());
        this.end = new Date(year, month + 3);

        this.#rangeSelector = new DateRangeSelector();
        this.#current = getMonthsDiff(now, start);
    }

    public intersectionObserverCallback = (entries: IntersectionObserverEntry[]): void => {
        if (entries.filter((entry) => entry.isIntersecting).length === 0) {
            return;
        }
        this.showMoreMonths(this.#showMoreMonthsStep);
        this.#dispatchShowMoreMonthsEvent(this.#showMoreMonthsStep);
        this.#update();
    };

    public clearSelection(): void {
        this.#rangeSelector.clear();
        const range = this.#rangeSelector.range;
        this.#dateSelection = range;

        this.selectState = null;

        this.dispatchEvent(
            new CustomEvent(DatePickerCalendarEvents.DATE_RANGE_SELECTED, {
                detail: {
                    start: range.start,
                    end: range.end,
                    firstUpdate: false
                }
            })
        );
    }

    protected attributeChangedCallback(name: string): void {
        if (name === 'selectedperiod') {
            this.#initSelectedPeriod();
            this.#update();
        }
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.#calendarBase = this.querySelector('.calendar-base') as HTMLElement;
        const arrivalDate = new Date(this.dataset.firstArrivalDate ?? '');

        if (this.dataset.firstArrivalDate && isDateValid(arrivalDate)) {
            this.#firstArrivalDate = arrivalDate;
        }

        this.#setEventListeners();

        this.#initSelectedPeriod();
        this.#update();

        setTimeout(() => this.scrollToCurrentMonth(), 0);
    }

    public disconnectedCallback(): void {
        this.#showHideObserver?.disconnect();
    }

    protected handleDayClick(event: CustomEvent): void {
        const { day }: { day: HTMLElement } = event.detail;

        if (typeof day.dataset.date === 'undefined' || day.dataset.disabled === '1') {
            return;
        }

        const date: Date = new Date(day.dataset.date);

        if (!isDateValid(date)) {
            return;
        }

        if (
            this.#rangeSelector.range.start !== null &&
            date.getTime() <= this.#rangeSelector.range.start.getTime()
        ) {
            this.#selectState = DatePickerCalendarSelectState.START;
        }

        if (this.#selectState === DatePickerCalendarSelectState.START) {
            this.#rangeSelector.startDate = date;
            this.#rangeSelector.endDate = null;
        }

        if (this.#selectState === DatePickerCalendarSelectState.END) {
            this.#rangeSelector.endDate = date;
        }

        if (this.#selectState === null) {
            this.#rangeSelector.selectDate(date);
        }

        const range = this.#rangeSelector.range;
        this.#dateSelection = range;

        this.dispatchEvent(
            new CustomEvent(DatePickerCalendarEvents.DATE_RANGE_SELECTED, {
                detail: {
                    start: range.start,
                    end: range.end,
                    firstUpdate: false
                }
            })
        );

        if (this.#selectState === DatePickerCalendarSelectState.END) {
            this.#current = getMonthsDiff(new Date(), date);
            this.scrollToCurrentMonth();
        }

        this.#update();
    }

    #navigateDatepicker(increment: number): void {
        if (!this.#calendarBase) {
            return;
        }

        this.#current += increment;

        if (this.#current < 0) {
            this.#current = 0;
        }

        if (this.#current + 4 > this.#calendarCounter) {
            const diff = this.#current - this.#calendarCounter + 4;
            for (let i = 1; i <= diff; i++) {
                this.end = new Date(this.end.getFullYear(), this.end.getMonth() + 1);
                this.addMonth(this.end.getFullYear(), this.end.getMonth() + 1);
                this.#calendarCounter++;
            }
            this.#dispatchShowMoreMonthsEvent(diff);
        }

        this.#update();
        this.scrollToCurrentMonth();
    }

    #update(): void {
        const selectedPeriod = this.#getSelectedPeriod();
        let id = 0;
        const available = this.#allowedDates?.map(
            (date: string): Availability => ({
                id: id++,
                date: date
            })
        );

        this.months.forEach((month): void => {
            month.selected = [selectedPeriod];
            month.defaultDisabled = typeof available !== 'undefined';
            month.available = available || [];
        });
    }

    #getSelectedPeriod(): DateSelection {
        const range = this.#rangeSelector.range;
        const start: string = range.start ? formatDate(range.start) : '';
        const end: string = range.end ? formatDate(range.end) : start;
        return { start, end };
    }

    #setEventListeners(): void {
        const navigatePreviousMonth: HTMLButtonElement | null =
            this.querySelector('[data-action="prev"]');
        const navigateNextMonth: HTMLButtonElement | null =
            this.querySelector('[data-action="next"]');

        navigatePreviousMonth?.addEventListener('click', () => this.#navigateDatepicker(-1));
        navigateNextMonth?.addEventListener('click', () => this.#navigateDatepicker(1));

        const moreMonthsButton: HTMLButtonElement | null = this.querySelector('#show-more-months');
        if (moreMonthsButton) {
            moreMonthsButton.addEventListener('click', () => {
                this.showMoreMonths(this.#showMoreMonthsStep);
                this.#dispatchShowMoreMonthsEvent(this.#showMoreMonthsStep);
                this.#update();
            });
        }

        this.#showMoreMonthsObserver();

        matchMediaAddEventListener(this.#tabletMediaQuery, () => {
            this.scrollToCurrentMonth();
        });

        const hiddenElement = this.#calendarBase?.closest('[hidden]');
        if (hiddenElement) {
            this.#showHideObserver = new MutationObserver(() => {
                if (hiddenElement?.hasAttribute('hidden') === true) return;
                this.#scrollWhenOpened();
            });
            this.#showHideObserver.observe(hiddenElement, {
                attributes: true,
                attributeFilter: ['hidden']
            });
        }
    }

    #showMoreMonthsObserver(): void {
        const target = this.querySelector('[data-role="calendar-scroll-observer"]');

        if (target === null || this.#tabletMediaQuery.matches) {
            return;
        }

        const observer = new IntersectionObserver(this.intersectionObserverCallback, {
            rootMargin: '0px',
            threshold: 1
        });

        observer.observe(target);
    }

    #scrollWhenOpened(): void {
        if (this.selection.start === null) {
            this.scrollToCurrentMonth();
            return;
        }

        this.#selectState = DatePickerCalendarSelectState.END;

        const start = new Date(this.selection.start);
        const monthStart = start.getMonth() + 1;
        const yearStart = start.getFullYear();
        this.months.forEach((monthElement, index): void => {
            const month = Number(monthElement.getAttribute('month'));
            const year = Number(monthElement.getAttribute('year'));
            if (month !== monthStart || year !== yearStart) return;
            this.#navigateDatepicker(index - this.#current);
        });
    }

    #getTranslateX(calendarBase: HTMLElement, count: number): number {
        if (count === 0) {
            return 0;
        }

        const months: NodeListOf<HTMLElement> = calendarBase.querySelectorAll(`calendar-month`);
        const month: HTMLElement = months[count];

        if (!month) {
            return 0;
        }

        const parentRect = calendarBase.getBoundingClientRect();
        const rect = month.getBoundingClientRect();

        return Math.floor(parentRect.left - rect.left);
    }

    public scrollToCurrentMonth(): void {
        if (!this.#calendarBase) {
            return;
        }

        if (!this.#tabletMediaQuery.matches) {
            this.#calendarBase.style.transform = 'translateX(0px)';
            if (this.#current > 0) {
                setTimeout(() => this.months[this.#current].scrollIntoView(), 0);
            }
            return;
        }

        const translateX = this.#getTranslateX(this.#calendarBase, this.#current);
        this.#calendarBase.style.transform = `translateX(${translateX}px)`;
    }

    #dispatchShowMoreMonthsEvent(extendDateRangeWithMonths: number): void {
        this.dispatchEvent(
            new CustomEvent(DatePickerCalendarEvents.SHOW_MORE_MONTHS, {
                detail: {
                    extendDateRangeWithMonths: extendDateRangeWithMonths,
                    state: this.selectState
                }
            })
        );
    }

    #initSelectedPeriod(): void {
        if (!this.hasAttribute('selectedperiod')) {
            return;
        }

        const rangeJson = this.getAttribute('selectedperiod') || '{}';
        const range = JSON.parse(rangeJson);
        const startDate = range.start ? new Date(range.start) : null;
        const endDate = range.end ? new Date(range.end) : null;
        this.#rangeSelector = new DateRangeSelector(startDate, endDate);
        this.#dateSelection = this.#rangeSelector.range;
    }
}

if (!customElements.get('datepicker-calendar')) {
    customElements.define('datepicker-calendar', DatePickerCalendar);
}
